import { ref } from 'vue';
import GlazeClient from '@framebridge/toolbox/apihelpers/glazeClient';

const baseUrl = SDG.Data.joineryHost || 'https://staging.framebridge.com';
const routeUrl = '/api/v1/delivery_estimates/generate';
const zipcode = ref(null);
const estimatedDate = ref(null);
const currentRecieveBy = ref(null);

function useDelivery() {
  const customerPackaging = ref(false);
  const expedited = ref(false);
  const fixedSizeBundle = ref(false);
  const postPurchaseDesignConsultation = ref(false);
  const quickShip = ref(false);

  if (zipcode.value === null) {
    const zip = sessionStorage.getItem('SDG-zip');
    if (zip) zipcode.value = zip;
  }

  function adjustConveyance(conveyance) {
    if (['upload', 'uploaded'].includes(conveyance)) {
      return 'upload';
    }
    return 'mailin';
  }

  async function fetchDeliveryEstimate(conveyance = 'upload', val = null) {
    const glazeClient = await new GlazeClient(
      null,
      {
        rejectIfNotOk: true,
        baseUrl,
      },
    )
      .post(routeUrl, {
        quick_ship: quickShip.value,
        customer_packaging: customerPackaging.value,
        expedited: expedited.value,
        fixed_size_bundle: fixedSizeBundle.value,
        post_purchase_design_consultation: postPurchaseDesignConsultation.value,
        conveyance: adjustConveyance(conveyance),
        zipcode: val || zipcode.value,
        test_date: '',
      });

    if (glazeClient && glazeClient?.data) {
      estimatedDate.value = glazeClient.data;
      const { deliveryEstimate } = glazeClient.data;

      if (deliveryEstimate) {
        const { getItBy } = deliveryEstimate;

        if (getItBy) {
          currentRecieveBy.value = getItBy;
        }
      }

      if (val) {
        updateZipcode(val);
      }

      return {
        estimatedDate: estimatedDate.value,
      };
    }

    return null;
  }

  async function fetchSingleDeliveryEstimate(val = null, conveyance = 'upload') {
    try {
      const glazeClient = await new GlazeClient(
        null,
        {
          rejectIfNotOk: true,
          baseUrl,
        },
      )
        .post(routeUrl, {
          quick_ship: quickShip.value,
          customer_packaging: customerPackaging.value,
          expedited: expedited.value,
          fixed_size_bundle: fixedSizeBundle.value,
          post_purchase_design_consultation: postPurchaseDesignConsultation.value,
          conveyance: adjustConveyance(conveyance),
          zipcode: val || zipcode.value,
          test_date: '',
        });

      if (glazeClient && glazeClient?.data) {
        const { deliveryEstimate } = glazeClient.data;

        if (deliveryEstimate) {
          const {
            earliestPromisedDeliveryOn,
            extendedDelayTimeframe,
            getItBy,
            latestPromisedDeliveryOn,
          } = deliveryEstimate;

          return {
            message: extendedDelayTimeframe,
            arrival: earliestPromisedDeliveryOn || latestPromisedDeliveryOn,
            dates: getItBy,
          };
        }
        return null;
      }

      return null;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  function updateZipcode(zip) {
    zipcode.value = zip;
    sessionStorage.setItem('SDG-zip', zip);

    return zipcode.value;
  }

  return {
    currentRecieveBy,
    estimatedDate,
    zipcode,
    fetchDeliveryEstimate,
    fetchSingleDeliveryEstimate,
    updateZipcode,
  };
}

export default useDelivery;
